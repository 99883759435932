import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Loading from './components/Loading';
import Landing from './components/Landing';
import SectionContact from './components/sections/SectionContact';
import PrivacyPolicy from './components/PrivacyPolicy';
import Footer from './components/sections/Footer';

import './App.css';

export default function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => (
      setLoading(true)
    ), 1500);
   });

  return (
    <BrowserRouter>

        <Switch>
          <Route path='/' exact>
            {loading ? <Landing /> : <Loading />}
          </Route>

          <Route path='/contact' exact>
            <SectionContact
              hideResume={true}
              showPortfolio={true}
              description="Please specify your reason for contact in the message box. I will get back to you within 12-24 hours!"
            />
          </Route>

          <Route path='/contact' exact>
            <PrivacyPolicy />
          </Route>

        </Switch>
        <Footer />
    </BrowserRouter>
  );
}
