import React from 'react';
import styled from 'styled-components';
// import FormPrimary from '../Forms';
import { Element } from 'react-scroll'

const SectionContact = ({description, hideResume, showPortfolio}) => {

    // const buttons = {hideResume, showPortfolio}
    return (
        <Element name='sectionContact'>
            <SectionWrapper id='sectionContact'>
                <HeaderContainer>
                    <PreHeader>Partner With Me</PreHeader>
                    <SectionHeader>Lets Do This!</SectionHeader>
                    <StyledParagraph>{description}</StyledParagraph>
                </HeaderContainer>
                {/* <FormPrimary buttons={buttons}/> */}
                <div class="hb-p-617797e8b3727b5c1fdb0ab3-1" style={{width: '100%'}}/>
                
                {
                    (function(h,b,s,n,i,p,e,t) {
                        h._HB_ = h._HB_ || {};h._HB_.pid = i;;;;
                        t=b.createElement(s);t.type="text/javascript";t.async=!0;t.src=n;
                        e=b.getElementsByTagName(s)[0];e.parentNode.insertBefore(t,e);
                    })(window,document,"script","https://widget.honeybook.com/assets_users_production/websiteplacements/placement-controller.min.js","617797e8b3727b5c1fdb0ab3")
                }
            </SectionWrapper>
        </Element> 
        
    )
}

const desktop = `(min-width: 768px)`;

const SectionWrapper = styled.section`
    width: 100%;
    background: #fff;
    z-index: 3;

    @media ${desktop} {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

const HeaderContainer = styled.div`
    padding: 0 1.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const PreHeader = styled.h3`
    color: var(--light-text);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 5px;
    font-size: .95rem;
    margin-top: 6rem;
    margin-bottom: .5rem;
    background-color: #7a2a37;
    height: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media ${desktop} {
        height: 4rem;
        min-width: 35%;
        max-width: 60%;
        padding: 2rem;
        text-align: center;
    }
`;

const SectionHeader = styled.h2`
    font-weight: 800;
    font-size: 2rem;
    color: var(--dark-text);
    text-transform: uppercase;
    margin-bottom: 3rem;
`;

const StyledParagraph = styled.p`
    font-size: 14px;
    color: var(--dark-text);
    margin-bottom: 30px;
    text-align: center;

    @media ${desktop} {
        width: 50%;
    }
`;

export default SectionContact
