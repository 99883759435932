import React, { useState } from 'react'
import styled from 'styled-components';
import { Link } from 'react-scroll';

import BrandLogo from '../assets/logo.svg';

const pages = [
    { title: 'Antoine Media', path: 'https://antoine.media', external: true},
    { title: 'Home', path: 'sectionHero', external: false},
    { title: 'About', path: 'sectionAbout', external: false},
    { title: 'Portfolio', path: 'sectionPortfolio', external: false},
    { title: 'Profile', path: 'sectionProfile'},
    { title: 'Contact', path: 'sectionContact', external: false},
    { title: 'Resume', path: 'https://www.linkedin.com/in/antoine-mcabee-b5b8b0159/', external: true},
]

const renderNavItems = (navActive, setNavActive) => {
    return pages.map((listItem, index) => 
        <ListItem key={index} navActive={navActive} >
            {listItem.external ? 
            <a href={listItem.path} rel="noopener noreferrer" target="_blank" >
                {listItem.title}
            </a>
            :
            <Link to={listItem.path} smooth={true} offset={0} duration={1000} onClick={() => setNavActive(!navActive)} >
                {listItem.title}
            </Link>}
            
        </ListItem>
    )
}

const Navbar = () => {

    const [navActive, setNavActive] = useState(false);

    return (
        <Nav navActive={navActive}>
            <NavWrapper>
                <Hamburger navActive={navActive} onClick={ () => setNavActive(!navActive)}>
                    <div className="ham-and-cheese" />
                    <div className="ham-and-cheese" />
                </Hamburger>

                <Link to={pages[0].path} smooth={true} offset={0} duration={1000} >
                    <NavBrand src={BrandLogo} />
                </Link>

                <NavList navActive={navActive} onClick={() => setNavActive(!navActive)}>
                    {renderNavItems(navActive, setNavActive.bind(this))}
                </NavList>
            </NavWrapper>
        </Nav>
    )
}

const mobile =  `(max-width: 1080px)`;

const Hamburger = styled.div`
    @media ${mobile} {
        display: flex;
        flex-direction: column;
        align-items: start;
        cursor: pointer;

        div {
            width: 20px !important;
            border-bottom: 2px solid #fff;
            padding-bottom: 5px;
            margin: 0;
            transition: .35s all ease-in-out;
            transform: ${props => props.navActive ? 'rotate(-45deg) translate(-0px, 1px)' : ''};
        }

        div:nth-of-type(2) {
            transform: ${props => props.navActive ? 'rotate(45deg) translate(-1px, -8px)' : ''};
        }
    }

`;

const Nav = styled.nav`
    background-color: #000;
    opacity: .92;
    height: 45px;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 2000;
    transition: .35s opacity ease-out;

    @media ${mobile} {
        opacity: ${props => props.navActive ? 1 : .92};
    }

    a:nth-of-type(1) {
        display: flex;

        @media ${mobile} {
            margin: auto;
        }
    }
`;

const NavBrand = styled.img`
    width: 50px;
    height: 50px;
    opacity: .7;
    transition: .35s all ease-in-out;
    cursor: pointer;

    &:hover{
        opacity: 1;
    }

    @media ${mobile} {
        margin: 0;
        transform: translateX(-15%);
    }
`

const NavWrapper = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 3rem;
    display: flex;
    align-items: center;

    @media ${mobile} {
        justify-content: start;
        padding: 0 1.5rem;
    }
`;

const NavList = styled.ul`
    width: 50%;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3rem;

    @media ${mobile} {
        margin-left: 0;
        background: #000;
        padding: 3rem;
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 45px;
        right: 0;
        justify-content: center;
        flex-direction: column;
        transform: ${props => props.navActive ? 'translateY(0%)' : 'translateY(100%)'};
        transition: transform 0.35s ease-in-out;
        transition-delay: ${props => props.navActive ? 0 : .30}s;
        z-index: 2005;
    }
`;

const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin: 0 .25rem;

    a {
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
        text-decoration: none;
        transition: .5s all ease-in-out;
        opacity: .7;

        &:hover {
            opacity: 1;
        }
    }

    @media ${mobile} {
        width: 100%;
        padding-bottom: 1.5rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #fff;
        transition: opacity ${props => props.navActive ? 1.5 : .35}s;
        transition-delay: ${props => props.navActive ? .75 : .35}s;
        opacity: ${props => props.navActive ? 1 : 0};

        a {
            text-align: left;
            margin: 0 !important;
        }
    }
`;

export default Navbar
