import React from 'react';
import styled from 'styled-components';

const Footer = () => {
    return (
        <Wrapper>
            <p className="copyright">&copy; 2022 Antoine Media LLC</p>
            <p className="credits">Powered By <a className='link' href="https://antoine.media">Antoine Media LLC</a></p>
            <p className="credits"><a className='link' href="/privacypolicy">Privacy Policy</a></p>
        </Wrapper>
    );
}

const Wrapper = styled.footer`
    background: #fff;
    width: 100%;
    min-height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border-top: 1px solid var(--dark-text);

    p {
        color: var(--dark-text);
        font-size: 12px;
    }

    .link {
        color: #aaa;
        cursor: pointer;
    }
`;



export default Footer;
