import React, { Suspense, lazy } from 'react';
import Navbar from './Navbar';
import SectionPortfolio from './sections/SectionPortfolio';
import SectionAbout from './sections/SectionAbout';
import SectionContact from './sections/SectionContact';
import Loading from './Loading';

import styled from 'styled-components';

const SectionProfile = lazy(() => import('./sections/SectionProfile'))
const SectionHero = lazy(() => import('./sections/SectionHero'))

const Landing = () => {
    return (
        <LandingWrapper>
            <Navbar />
            <Suspense fallback={<Loading />}>
                <SectionHero />
            </Suspense>
            <SectionAbout />
            <SectionPortfolio />
            <Suspense fallback={<Loading />}>
                <SectionProfile />
            </Suspense>
            <SectionContact 
                description="Have questions about my work or experience? Shoot me a text at (254) 266-6556 or put your questions in the message field and I'd be happy to get back to you!"
            />
        </LandingWrapper>
    )
}

const LandingWrapper = styled.div`
    position: relative;
`

export default Landing;
