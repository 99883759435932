import React from 'react';
import styled from 'styled-components';

export const ImgCard = React.forwardRef(({ img, mt, mb, mr, ml, title, text }, ref) => {

    // const [src, { blur }] = useProgressiveImg(
    //     imgLQ,
    //     imgHQ
    // );

    return (
        <ImgCardBG ref={ref} marginTop={mt} marginBottom={mb} marginRight={mr} marginleft={ml} >
            <Image src={img} />
            <Container>
                <CardTitle>{title}</CardTitle>
                <CardText>{text}</CardText>
            </Container>
        </ImgCardBG>
    )
})

export const SolidCard = ({ title, text, mt, mb, icon }) => {
    return (
        <CardBG marginTop={mt} marginBottom={mb}>
            <Container>
                <CardTitle>{title}</CardTitle>
                <CardText>{text}</CardText>
            </Container>
        </CardBG>
    )
}

export const SolidCardLogo = ({ title, text, mt, mb, icon }) => {
    return (
        <CardBG marginTop={mt} marginBottom={mb}>
            <Container>
                <Icon src={icon} />
                <CardTitle>{title}</CardTitle>
                <CardText>{text}</CardText>
            </Container>
        </CardBG>
    )
}

ImgCard.defaultProps = {
    title:'',
    text:'',
    mt:'',
    mb:''
};

const desktop = `(min-width: 768px)`;

const ImgCardBG = styled.div`
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    border-radius: 5px;
    position: relative;
    background-color: #000;
    border-radius: 5px;
    box-shadow: 10px 11px 45px -20px rgba(0,0,0,0.75);
    filter: ${props => props.blur ? "blur(5px)" : "none"};

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 1.25em;
        bottom: 1.25em;
        left: 1.25em;
        right: 1.25em;
    }

    &::before {
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff; 
        transform: scale(0,1);
        transition: transform ease-out 250ms;
    }

    &::after {
        border-left: 1px solid #fff;
        border-right: 1px solid #fff; 
        transform: scale(1,0);
        transition: transform ease-out 250ms;
    }

    &:hover::before {
        transform: scale(1,1); 
        opacity: 1;
    }

    &:hover::after {
        transform: scale(1,1); 
        opacity: 1;
    }

    @media ${desktop} {
        max-width: 40%;
        margin-right: ${props => props.marginRight};
        margin-left: ${props => props.marginLeft};
    }
`;

const CardBG = styled(ImgCardBG)`
    width: 100%;
    height: 30vh;
    border-radius: 5px;
`;

const Image = styled.img`
    max-width: 100%;
    display: block;
    border-radius: 5px;
    transition: opacity ease-out 250ms;
    opacity: 1;

    ${ImgCardBG}:hover & {
        opacity: .25;
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
`;

const CardTitle = styled.h3`
    font-weight: 900;
    font-size: .85rem;
    letter-spacing: 5px;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 20%;
    // left: 50%;
    // transform: translate(-50%, -400%);
    color: #fff;
    opacity: 0;
    transition: opacity ease-out 250ms;

    ${ImgCardBG}:hover & {
        opacity: 1;
    }
`;

const CardText = styled.p`
    position: absolute;
    font-size: .75rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    transition: opacity ease-out 250ms;
    opacity: 0;

    ${ImgCardBG}:hover & {
        opacity: 1;
        text-align: center
    }
`;

const Icon = styled.img`
    object-fit: cover;
    width: 35%;
    // height: 350px;
    transition: opacity ease-out 250ms;

    ${ImgCardBG}:hover & {
        opacity: 0;
    }

`;