import React from 'react';
import styled from 'styled-components';
import {SecondaryButton} from '../Buttons';
import { Element } from 'react-scroll';
import { useProgressiveImg } from '../ProgressiveImg';

import Headshot from '../../assets/headshot-halftone.png';

const SectionAbout = () => {
    const [src, { blur }] = useProgressiveImg(
        Headshot,
        Headshot
    );

    return (
        <Element name='sectionAbout'>
            <SectionWrapper id='sectionAbout'>
                <SeperationBar>
                    <SectionHeader>
                        Behind The Code
                    </SectionHeader>
                    <EstablishedParagraph>est. 99"</EstablishedParagraph>
                </SeperationBar>

                <Container>
                    <Image src={src} blur={blur} alt="Antoine McAbee, Software Developer"/>
                    
                    <ContentContainer>
                        <SectionSubHeader>Meet Me</SectionSubHeader>
                        <StyledParagraph>
                            Technology advocate experienced in collaborative technical environments, technical solution design and architecture, and full stack development of web and mobile applications and SaaS solution integration. Strong experience in automation, responsive web development, and cybersecurity principles.  analytical problem solver known for developing creative solutions and identifying unknown hazards. Experienced working in cross functional teams with the ability to develop and deliver speedy and promise-driven results. Excel at balancing multiple tasks while adhering to eventful timelines. Exceptionally gifted in leadership, communicating with stakeholders, and interfacing with coworkers. 
                            <br/>
                            <br/>
                            
                        </StyledParagraph>

                        <SecondaryButton mb='50px' path='sectionContact' title="lets connect" />
                    </ContentContainer>
                </Container>
            </SectionWrapper>
        </Element>
       
    )
}

const desktop = `(min-width: 768px)`;

const SectionWrapper = styled.section`
    background: linear-gradient( rgba(245, 245, 247, .5), 15%, #fff 90%);
    width: 100%;
    height: 100vh;
    box-shadow: 0 20px 20px 3px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 5;

    @media ${desktop} {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: auto;
    }
`;

const Container = styled.div`
    padding: 0 1.5rem;

    @media ${desktop} {
        padding: 0;
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        justify-content: center;
    }
`;

const SectionHeader = styled.h2`
    font-weight: 800;
    font-size: 2rem;
    color: var(--light-text);
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 12px;

    span {
        font-weight: 400;
    }
`;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${desktop} {
        width: 70%;
    }
`;

const SeperationBar = styled.div`
    background: #000;
    height: 35%;
    clip-path: polygon(0 0, 1600px 0, 1600px 40%, 0 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    @media ${desktop} {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
        width: 100%;
        min-height: 45vh;
    }
`;

const Image = styled.img`
    display: none;
    @media ${desktop} {
        display: inline;
        max-width: 35%;
        justify-self: center;
    }
`;

const EstablishedParagraph = styled.p`
    font-size: 10px;
    color: #dddddd;
`;

const SectionSubHeader = styled.h3`
    color: var(--dark-text);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 5px;
    font-size: .95rem;
    padding-bottom:20px;
`;  

const StyledParagraph = styled.p`
    font-size: 14px;
    color: var(--dark-text);
    margin-bottom: 30px;
`;

export default SectionAbout;