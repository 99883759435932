import React from 'react';
import styled from 'styled-components';
import { ImgCard } from '../Cards';
import { ExternalButton } from '../Buttons';
import { Element } from 'react-scroll'
import { Parallax } from 'react-parallax';

const SectionPortfolio = () => {

    return (
        <Element name='sectionPortfolio'>
            
            <SectionWrapper id='sectionPortfolio'>
                <Parallax
                    blur={0}
                    bgImage={require('../../assets/logo-red.svg')}
                    bgImageAlt="Antoine McAbee Software Developer Logo"
                    strength={500}
                    bgClassName='mobileLogo'
                >
                    <Container>
                        <CardContainerDesktop>
                            <ImgCard
                                img="https://amphoto-full.s3.us-east-2.amazonaws.com/antoinemcabeeDev/AntoineMcAbeeDev_DellTechnologiesIntern.png"
                                mr="1.5rem"
                                title="Cybersecurity Engineer, Intern"
                                text="Current Cybersecurity Intern for Dell Technologies focusing on automation enablement in WAF and vulnerability scanning teams."
                            />

                            <ImgCard
                                img="https://amphoto-full.s3.us-east-2.amazonaws.com/antoinemcabeeDev/AntoineMcAbeeDev_TysonFoodsIntern.png"
                                // mt="1.5rem"
                                title="Developer Intern"
                                text="Former Intern Lead. Responsibilities ranged from development of a custom applications, to directly interfacing with customers, and sourcing applications for company-wide integrations."
                            />

                            <ImgCard
                                img="https://amphoto-full.s3.us-east-2.amazonaws.com/antoinemcabeeDev/AntoineMcAbeeDev_AntoineMediaOwner.png"
                                mr='1.5rem'
                                mt="1.5rem"
                                title='Owner'
                                text='Owner of Antoine Media LLC. Designed and developed a custom web application using Next.JS.'
                            />     

                            <ImgCard
                                img="https://amphoto-full.s3.us-east-2.amazonaws.com/antoinemcabeeDev/AntoineMcAbeeDev_AntiochWaco.png"
                                // mr='1.5rem'
                                mt="1.5rem"
                                title='Media Lead'
                                text='Media Team Leader of over 30 volunteers and social platforms of over 10K.'
                            /> 
                        </CardContainerDesktop>

                        <ExternalButton 
                            title='learn more'
                            path='https://github.com/antoinemcabee'
                            mb='4rem'
                            mt='4rem'

                        />
                    </Container>
                </Parallax>
            </SectionWrapper>
            
        </Element>
    )
}

const desktop = `(min-width: 768px)`;

const SectionWrapper = styled.section`
    min-height: 100vh;
    width: 100%;
    background-color: var(--light-text);
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${desktop} {
        min-height: 60vh;
    }

    .mobileLogo {
        width: 30px;
    }
    
`;

const Container = styled.div`
    padding: 0 1.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${desktop} {
    }
`;

const CardContainerDesktop = styled.div`
    @media ${desktop} {
        margin-top: 3rem;
        width: 100%;
        height: 100%;
        display: flex;
        margin-bottom: 3rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        
    }
`;

export default SectionPortfolio;