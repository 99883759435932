import React from 'react';
import styled, {keyframes} from 'styled-components';

import Logo from '../assets/logo.svg';

const Loading = () => {
    return (
        <LoadingBG>
            <Container>
                <TextTop>antoinemcabee.dev</TextTop>
                <LogoPulse src={Logo} />
                <TextBottom>loading...</TextBottom>
            </Container>
        </LoadingBG>
    )
}

const LoadingBG = styled.div`
    width: 100%;
    height: 100vh;
    background: #000;
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TextBottom = styled.p`
    position: absolute;
    bottom: 15%;
    font-size: .95rem;
    font-weight: 800;
    color: var(--light-text);
    text-transform: uppercase;
    letter-spacing: 5px;
`;

const TextTop = styled(TextBottom)`
    top: 10%;
`;

const pulse = keyframes`
    0% {
        transform: scale(.85);
    }
    50% {
        transform: scale(1.10);
    }
    100% {
        transform: scale(.85);
    }
`;

const LogoPulse = styled.img`
    width: 30%;
    display: inline-block;
    animation: ${pulse} 3s infinite ease-out;
`;

export default Loading
