import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll'

function PrimaryButton({ title, path, mb, ml , mt}) {
    return (
        <ButtonWrapper marginBottom={mb} marginLeft={ml} marginTop={mt}>
            <ButtonTitle>
                <Link to={path} smooth={true} offset={0} duration={1000}>
                    {title}
                </Link>
            </ButtonTitle>
        </ButtonWrapper>
    )
}

function SecondaryButton({ title, path, mb, ml, mt }) {
    return (
        <SecButtonWrapper marginBottom={mb} marginLeft={ml} marginTop={mt}>
            <SecButtonTitle>
                <Link to={path} smooth={true} offset={0} duration={1000}>
                    {title}
                </Link>
            </SecButtonTitle>
        </SecButtonWrapper>
    )
}

function ExternalButton({ title, path, mb, ml, mt }) {
    return (
        <SecButtonWrapper marginBottom={mb} marginLeft={ml} marginTop={mt}> 
            <a href={path} className='external-primary' rel="noopener noreferrer" target="_blank">
                {title}
            </a>
        </SecButtonWrapper>
    )
}

function SubmitButton({ title, path, mb, ml, mt }) {
    return (
        <SecButtonWrapper marginBottom={mb} marginLeft={ml} marginTop={mt}>
            <SubmitTitle>
                {title}
            </SubmitTitle>
        </SecButtonWrapper>
    )
}

const ButtonWrapper = styled.div`
    min-width: 90px;
    max-width: 250px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #fff;
    border-radius: 3px;
    transform: translate(0%);
    font-weight: 800;
    font-size: 16px;
    margin-bottom: ${props => props.marginBottom};
    margin-left: ${props => props.marginLeft};
    margin-top: ${props => props.marginTop};

    &:hover {
        transition: all .3s ease;

        div {
            color: #000;
        }
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 0px;
        height: 100%;
        background: #fff;
        transform-origin: left bottom;
        transition-property: width height;
        transition-duration: .3s;
        z-index: -1;
    }

    &:hover::after {
        width: 100%;
        height: 100%;
    }


    //for download button
    .download {
        color: #000;

        &:hover {
            color: #fff;
        }
    }

    .external-primary {
        color: #000;
        padding: 0 10px;
        transition: all .45s ease-in-out;
        cursor: pointer;

        &:hover {
            color: #fff
        }
    }
`;

const ButtonTitle = styled.div`
    color: #fff;
    padding: 0 10px;
    transition: all .45s ease-in-out;
    cursor: pointer;
`;

const SecButtonWrapper = styled(ButtonWrapper)`
    border-color: #000;

    &::after {
        background: #000;
    }

    &:hover {
        div {
            color: #fff;
        }
        a {
            color: #fff;
        }
        button {
            color: #fff;
        }
    }
`;
const SecButtonTitle = styled(ButtonTitle)`
    color: #000;
`;

const SubmitTitle = styled.button.attrs({
    type: 'submit'
})`
    font-weight: 800;
    font-size: 16px;
    color: #000;
    background: transparent;
    border: none;
    padding: 0 10px;
    transition: all .45s ease-in-out;
    cursor: pointer;
    outline: none;

    /* &:hover {
        color: #fff;
    } */
`;

export {PrimaryButton, SecondaryButton, ExternalButton, SubmitButton};